import React,{createContext,useContext,useState} from "react";
export const AppContext=createContext(null);
export const AppProvider=({children})=>{
    let [place, setPlace] = useState("")
    // console.log("place2",place)
    let [active, setActive] = useState("1")
	let [dealsTabActive, setDealsTabActive] = useState("All")
    let [SportsKeyword, setSportsKeyword] = useState("")
    let [hotelUrl, setHotelUrl] = useState("")
    let [flightUrl, setFlightUrl] = useState("")
    let [BannerPic, setBannerPic] = useState("");
    let [BannerAdv, setBannerAdv] = useState("");
    let [popular_Entertainment_Click, setPopular_Entertainment_Click] = useState("");
    let [sportsType, setSportsType] = useState("");
    let [activeTab, setActiveTab] = useState("");

    return (
        <AppContext.Provider value={{place,setPlace,active,setActive,dealsTabActive,setDealsTabActive,SportsKeyword,setSportsKeyword,hotelUrl,setHotelUrl,flightUrl,setFlightUrl,BannerPic, setBannerPic,popular_Entertainment_Click, setPopular_Entertainment_Click,sportsType, setSportsType,activeTab,setActiveTab, BannerAdv,setBannerAdv}}>
             {children}
        </AppContext.Provider>
    )
}
export function UsePlaceAuth(){
    return useContext(AppContext)
}