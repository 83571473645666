import React from 'react';
import "./DataLoader.css"
const DataLoader = () => {
  return (
    <>
  <div className="loading">
  <div align="center" >
  <div className="contener_general">
      <div className="contener_mixte"><div className="ballcolor ball_1">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_2">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_3">&nbsp;</div></div>
      <div className="contener_mixte"><div className="ballcolor ball_4">&nbsp;</div></div>
  </div>
  </div>
  </div>


    </>
  );
};
export default DataLoader;